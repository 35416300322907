import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Button, OccasionSelector, Loading } from "../../.."
import { GetCardAndOccasion, GetDigitalCardOccasions } from "../../../../Code/Data"
import { cleanHtmlTags, parseQuerystring } from "../../../../Code/Utilities"
import { usePurchaseData } from "../../../../Context/purchase"
import { useCartData } from "../../../../Context/cart"
import CardsByOccasionSelector from "../../../Purchase/CardsByOccasionSelector"
import styles from "./styles.module.scss"

function Step1({ onContinue }) {
  const { INDEX, DI, SHCIO, CARD_ID, OCC_ID, OID, TYPE } = parseQuerystring(window.location.search, true);

  const [loading, setLoading] = useState(false)

  const location = useLocation()
  const { digitalCardData, setDigitalCardData } = usePurchaseData()
  const { cart, cartIsLoading = loading } = useCartData()

  const cartItemInfo = location.state?.cartItemInfo;
  const { occasion, occasionList, cardList, card, customImage, index } = digitalCardData

  useEffect(() => {
    async function getQueryData() {
      let occasions = occasionList;

      if (!occasions) {
        setLoading(true)
        occasions = await GetDigitalCardOccasions(SHCIO)
        setLoading(false)

        if (occasions) {
          setDigitalCardData({
            ...digitalCardData,
            occasionList: occasions
          });
        }
      }

      //User is editing shopping cart item and Card info is available to be loaded from the react-router location/history state object.
      if (cartItemInfo && cartItemInfo.ItemIndex !== index) {
        LoadFromCartItemInfo(cartItemInfo)
      }
      else if (!isNaN(parseInt(CARD_ID)) && (card.CardId !== parseInt(CARD_ID))) {
        let foundCard = cardList.find(c => c.CardId === Number(CARD_ID));
        if (!foundCard) {
          setLoading(true)
          const { Card } = await GetCardAndOccasion(CARD_ID)
          setLoading(false)
          foundCard = Card;
        }
        if (foundCard) {
          setDigitalCardData({
            ...digitalCardData,
            occasion: (occasions?.find(o => o.OccasionId === foundCard.OccasionId) || { OccasionId: foundCard.OccasionId, OccasionName: foundCard.OccasionName }),
            card: foundCard.CardId,
          })
        }
      }
      else if (!isNaN(parseInt(OCC_ID || OID)) && (!occasion || (parseInt(OCC_ID || OID) !== occasion.OccasionId))) {
        const occasion = occasions.find(
          ({ OccasionId }) => OccasionId === Number(OCC_ID || OID)
        )
        if (occasion) {
          setDigitalCardData({
            ...digitalCardData,
            occasion,
            card: occasion.FirstCard,
          })
        }
      }
      else if (TYPE && digitalCardData.deliveryMethod !== Number(TYPE)) {
        setDigitalCardData({
          ...digitalCardData,
          deliveryMethod: Number(TYPE)
        })
      }
    }
    getQueryData()
  }, [])

  //This useEffect needs data from the Cart Context
  useEffect(() => {
    if (cartIsLoading || !cart) return;

    //User is editing shopping cart item by index. Card info is only available from the Cart Context.
    if (!isNaN(parseInt(INDEX)) && parseInt(INDEX) !== index) {
      console.log('LOADING FROM INDEX', INDEX);
      LoadFromCartItemInfo(GetCartItemInfo(Number(INDEX)))
    }
    //User is editing shopping cart item by old-style index. Card info is only available from the Cart Context.
    else if (!isNaN(parseInt(DI)) && parseInt(DI) !== index) {
      console.log('LOADING FROM DI', INDEX);
      LoadFromCartItemInfo(GetCartItemInfo(Number(DI)))
    }
  }, [cart, cartIsLoading])

  const LoadFromCartItemInfo = cartItemInfo => {
    if (!(cartItemInfo && cartItemInfo.PurchaseItemInfo)) return;
    const {
      Card,
      Denomination,
      SmsFrom,
      CardMessage,
      CardGreeting,
      DigitalCardDeliveryType,
      Recipients,
      NumberOfPrintCards,
      CustomCardImageURL,
      SendDateUtc,
      CustomCardSectionHtml
    } = cartItemInfo.PurchaseItemInfo;
    let cards = cardList; //cardList is loaded from purchaseContext's digitalCardData object
    if (Card && cards && cards.length) {
      //Move selected card to front of list
      cards = [Card, ...cards.filter(c => c.CardId !== card.CardId)];
    }
    setDigitalCardData({
      index: cartItemInfo.ItemIndex,
      occasion: (occasionList?.find(o => o.OccasionId === Card.OccasionId) || { OccasionId: Card.OccasionId, OccasionName: Card.OccasionName }),
      card: Card,
      cardList: cards,
      amount: Denomination,
      from: SmsFrom,
      message: CardMessage,
      greeting: CardGreeting,
      deliveryMethod: DigitalCardDeliveryType,
      recipients: Recipients,
      numOfPrintCards: NumberOfPrintCards,
      customImage: CustomCardImageURL,
      sendDate: SendDateUtc ? new Date(SendDateUtc) : null,
      customText: cleanHtmlTags(CustomCardSectionHtml)
    });
  }

  const GetCartItemInfo = cartItemIndex => {
    if (!cart) console.error('Could not find context cart', cart);
    const cartItem = cart.Items.find(({ ItemIndex }) => ItemIndex === cartItemIndex);
    if (process.env.REACT_APP_DEV_BUILD === "true") {
      if (cartItem) {
        console.error('Could not find cart item index' + cartItemIndex.toString(), cart);
      }
      else {
        console.log('Cart Item found in Cart Context', cartItem);
      }
    }
    return cartItem;
  }

  const handleChangeOccasion = newOccasion => {
    if (newOccasion && (occasion?.OccasionId !== newOccasion.OccasionId)) {
      setDigitalCardData({
        ...digitalCardData,
        occasion: newOccasion,
        card: null,
        cardList: null
      })
    }
  }

  const handleLoadOccasion = cardList => {
    setDigitalCardData({
      ...digitalCardData,
      cardList
    })
  }

  const PageHelmet = () => {
    if (occasion?.PageTitle || occasion?.MetaDescription || occasion?.MetaKeywords) {
      return <Helmet>
        {occasion?.PageTitle && <title>{occasion.PageTitle}</title>}
        {occasion?.MetaDescription && <meta name="description" content={occasion.MetaDescription} />}
        {occasion?.MetaKeywords && <meta name="keywords" content={occasion.MetaKeywords} />}
      </Helmet>;
    }
  };

  /**
   * Handles the selection of a new card by updating the digital card data state.
   * If a new card is selected, it moves the card to the front of the card list
   * to ensure it is visible in the carousel when navigating back.
   * It also updates the custom image and custom text based on the selected card and occasion.
   *
   * @param {CardInfo} newCard - The newly selected card object.
   * @param {number} newCard.CardId - The unique identifier of the card.
   * @param {boolean} [newCard.HasCustomSection] - Indicates if the card has a custom section.
   * @param {string} [newCard.CustomSectionDefaultHtml] - The default HTML content for the custom section.
   */
  const handleChangeCard = newCard => {
    if (!newCard || (newCard.CardId === card?.CardId)) return;
    //When card is selected, it is moved to the front of the cards list, in order to assure it is visible in the carousel if they go back to change it.
    let cards = cardList;
    if (newCard && cards && cards.length) {
      //Add selected card to front of list
      cards = [newCard, ...cards.filter(c => c.CardId !== newCard.CardId)];
    }
    setDigitalCardData({
      ...digitalCardData,
      card: newCard,
      customImage: occasion.OccasionId === 54 ? customImage : null,
      cardList: cards,
      customText: newCard && newCard.HasCustomSection ? cleanHtmlTags(newCard.CustomSectionDefaultHtml) : null,
      amount: (newCard?.PresetDenomination ? newCard.PresetDenomination : digitalCardData.amount)
    })
  }

  const handleUploadImage = customImageURL => {
    if (customImageURL) {

      setDigitalCardData({
        ...digitalCardData,
        customImage: customImageURL
      })
    }

  }

  const validateFields = () => {
    if (!occasion?.OccasionId) {
      return false
    }
    // occasion.OccasionId === 54 (Custom Image)
    if (occasion.OccasionId === 54) {
      return !!customImage
    }
    if (!card?.CardId) {
      return false
    }
    return true
  }

  if (loading) {
    return <Loading />
  }

  return (<>
    <PageHelmet />
    <div className={styles.container}>
      <div className={styles.title}>Let's Design Your Gift Card</div>
      {(!!occasionList && occasionList.length > 0) ?
        <OccasionSelector
          className={styles.occasionSelector}
          occasion={occasion}
          occasions={occasionList}
          onChange={handleChangeOccasion}
        /> : null
      }
      {!!occasion && (
        <CardsByOccasionSelector
          occasionName={1}
          cardId={card?.CardId}
          customImage={customImage}
          cardList={cardList}
          onLoadOccasion={handleLoadOccasion}
          onChange={handleChangeCard}
          onUploadImage={handleUploadImage}
        />
      )}
      <Button
        className={styles.btnContinue}
        disabled={!validateFields()}
        onClick={onContinue}>
        Continue
      </Button>
    </div>
  </>
  )
}

export default Step1
