import React, { useEffect, useRef, useState } from "react"
import { Loading } from "../../.."
import {
  AddDirectToCharityDonationsApi,
  GetCharityByIdOrName,
} from "../../../../Code/Data"
import { parseQuerystring } from "../../../../Code/Utilities"
import { usePurchaseData } from "../../../../Context/purchase"
import bgImage from "../../../../images/DirectDonationStep1.png"
import styles from "./styles.module.scss"

function Step1({
  onContinue,
  handleChangeAmount,
  item,
  items,
  setItem,
  setErrMsg,
  handleAdd,
  shareWithCharities,
  setShareWithCharities,
}) {
  const { CHID } = parseQuerystring(window.location.search)
  const [loading, setLoading] = useState(false)
  const [preselCharity, setPreselCharity] = useState(null)
  const itemRef = useRef(item)

  useEffect(() => {
    itemRef.current = item
  }, [item])

  useEffect(() => {
    const fetchCharity = async () => {
      if (!!CHID) {
        const { Succeeded, CharityInfo, ErrorMessage } =
          await GetCharityByIdOrName(CHID)
        if (Succeeded) {
          setPreselCharity(CharityInfo)
          setItem(prevItem => ({ ...prevItem, charity: CharityInfo }))
        } else {
          setErrMsg(ErrorMessage)
        }
      }
    }

    fetchCharity()
  }, [])

  useEffect(() => {
    const handleKeyPress = event => {
      if (event.key === "Enter" && item?.amount > 1) {
        !!preselCharity ? handleConfirmPreselCharity() : onContinue()
      }
    }
    
    document.addEventListener("keydown", handleKeyPress)

    return () => {
      document.removeEventListener("keydown", handleKeyPress)
    }
  
  }, [preselCharity, onContinue])
  

  const handleChange = e => {
    setItem(prevItem => ({ ...prevItem, amount: e.target.value }))
    handleChangeAmount(e)
  }

  const handleConfirmPreselCharity = async () => {
    handleAdd()
    const payload = items.map(({ index, charity, amount }) => {
      return {
        ItemIndex: index,
        Charity: charity,
        ShareWithCharities: shareWithCharities,
        Quantity: 1,
        Denomination: amount,
      }
    })
    setLoading(true)
    const { Succeeded, ErrorMessage } = await AddDirectToCharityDonationsApi(
      payload
    )
    if (Succeeded) {
      window.location.href = "/Purchase/ShoppingCart"

      // history.push("/Purchase/ShoppingCart")
    } else {
      setErrMsg(ErrorMessage)
    }
    setLoading(false)
  }

  if (loading) {
    return <Loading />
  }

  return (
    <div className={styles.container}>
      <img
        className={styles.img}
        src={bgImage}
        width={
          window.innerWidth >= 768 && window.innerWidth <= 1024 ? "53vw" : "66%"
        }
        alt="Veterans day flag"
      />
      <div className={styles.content}>
        <div className={styles.title}>
          {!!preselCharity ? (
            <>
              <span className={styles.donatingTo}>Donating to: </span>
              <div className={styles.charityName}>
                {preselCharity.CharityName}
              </div>
            </>
          ) : (
            "How much would you like to donate?"
          )}
        </div>
        <input
          className={styles.inputAmount}
          type="number"
          min={2}
          title="A minimum donation of $2 is required."
          value={item.amount}
          onChange={handleChange}
          autoFocus
        />
        {item.amount > 1 && (
          <div className={styles.amountLbl}>
            <span style={{ display: "block" }}>
              ${parseFloat(item.amount).toFixed(2)}
            </span>
            <span className={styles.tag}>Your generosity is so awesome!</span>
          </div>
        )}
        <button
          disabled={!(item?.amount > 1)}
          className={styles.continueBtn}
          onClick={!!preselCharity ? handleConfirmPreselCharity : onContinue}>
          {preselCharity ? "Donate" : "Continue"}
        </button>
        {!!preselCharity && item.amount > 0 && (
          <>
            <label className={styles.checkbox}>
              <input
                type="checkbox"
                name="ShareWithCharities"
                checked={shareWithCharities}
                onChange={() => setShareWithCharities(!shareWithCharities)}
              />
              I wish to share my information with {preselCharity?.CharityName}
            </label>
            <div className={styles.changePreselCharity} onClick={onContinue}>
              change charity
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Step1
