import React, { useState } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { toast } from "react-toastify"
import Select from "react-select"
import { SendReceipt, SendTaxReceipt, StatesList } from "../../../Code/Data"
import { Button } from "../../"
import styles from "./styles.module.scss"

export default function ModalDonation({ type, onClose }) {
  const [redeemerInfo, setRedeemerInfo] = useState({})
  const [loading, setLoading] = useState()

  const handleChangeState = e => {
    setRedeemerInfo({
      ...redeemerInfo,
      State: e.value
    })
  }

  const handleChange = e => {
    setRedeemerInfo({
      ...redeemerInfo,
      [e.target.name]: e.target.value
    })
  }

  const handleSend = async () => {
    let request;
    switch (type) {
      case 'donate':
        request = SendReceipt;
        break;
      case 'tax':
        request = SendTaxReceipt;
        break;
      default:
        return;
    }
    setLoading(true)
    const { Succeeded, ErrorMessage } = await request(redeemerInfo)
    setLoading(false);
    if (Succeeded) {
      onClose('The email has been sent successfully.');
    }
    else {
      toast.error(ErrorMessage, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 7000
      });
    }
  }

  const {
    FirstName,
    LastName,
    Email,
    Phone,
    MailingAddress,
    Comment,
    City,
    Zip,
    State,
    ShareWithCharities
  } = redeemerInfo || {}

  return (
    <Modal isOpen={type} toggle={onClose} backdrop={false} className="update--modal">
      <ModalHeader>Redeemer Info</ModalHeader>
      <ModalBody>
        <div className={styles.content}>
          <div className={styles.row}>
            <input
              name="FirstName"
              placeholder="First Name *"
              value={FirstName}
              onChange={handleChange}
            />
            <input
              name="LastName"
              placeholder="Last Name *"
              value={LastName}
              onChange={handleChange}
            />
          </div>
          <div className={styles.row}>
            <input
              name="Email"
              placeholder="Email *"
              value={Email}
              onChange={handleChange}
            />
            <input
              name="Phone"
              placeholder="Phone"
              value={Phone}
              onChange={handleChange}
            />
          </div>
          <div className={styles.row}>
            <input
              name="MailingAddress"
              placeholder="Mailing Address"
              value={MailingAddress}
              onChange={handleChange}
            />
          </div>
          <div className={styles.row}>
            <input
              name="City"
              placeholder="City"
              value={City}
              onChange={handleChange}
            />
            <Select
              name="State"
              className={styles.stateSelector}
              classNamePrefix="state-select-small"
              placeholder="State"
              value={StatesList.find(({ value }) => value === State)}
              onChange={handleChangeState}
              options={StatesList}
              styles={{ zIndex: 4 }}
            />
            <input
              name="Zip"
              placeholder="ZIP"
              value={Zip}
              onChange={handleChange}
            />
          </div>
          <div className={styles.row}>
            <textarea
              name="Comment"
              placeholder="Comment"
              value={Comment}
              onChange={handleChange}
            />
          </div>
          <label className={styles.checkbox}>
            <input
              type="checkbox"
              name="ShareWithCharities"
              checked={ShareWithCharities}
              onChange={handleChange}
            />{" "}
            I wish to share my information with the charities I selected.
          </label>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className={styles.footer}>
          <Button
            outline
            color="yellow"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            color="Red"
            disabled={!FirstName || !LastName || !Email}
            loading={loading}
            onClick={handleSend}
          >
            Send
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}
