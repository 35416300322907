import React, { createContext, useContext, useEffect, useState } from "react"
import { GetLoggedInUserApi, LogoutUserApi } from "../Code/Data"

const UserContext = createContext()

export const UserProvider = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState()

  useEffect(() => {
    getLoggedInUser()
  }, [])

  const getLoggedInUser = async () => {
    setLoading(true)
    const result = await GetLoggedInUserApi()
    if (result?.Succeeded && result?.User){
      setUser(result.User)
      setLoading(false)
    }
  }

  const logout = async () => {
    const { Succeeded } = await LogoutUserApi()
    Succeeded && setUser()
  }

  return (
    <UserContext.Provider
      value={{
        loading,
        loggedInUser: user,
        setUser: setUser,
        refreshUser: getLoggedInUser,
        logout,
      }}>
      {children}
    </UserContext.Provider>
  )
}

export const useUserData = () => useContext(UserContext)
