import React, { useEffect, useState } from "react"
import { Loading, RedBar } from "../../Components"
import { useCartData } from "../../Context/cart"
import CharityPreSelector from "./CharityPreSelector"
import Checkout from "./Checkout"
import styles from "./styles.module.scss"

function ShoppingCart() {
  const [completeCharitySelect, setCompleteCharitySelect] = useState(false)
  const [checkoutReady, setCheckoutReady] = useState(false)
  const { loading, cart, refreshShoppingCart } = useCartData()

  useEffect(() => {
    // refreshShoppingCart()
  }, [])

  useEffect(() => {
    debugger
    if (!loading && !!cart) {
      setCheckoutReady(completeCharitySelect || !cart.HasRedeemableCards)
    }
  }, [cart, completeCharitySelect])

  const handleCompleteCharitySelect = () => {
    setCompleteCharitySelect(true)
    refreshShoppingCart()
  }

  const renderContent = () => {
    if (loading) {
      return <Loading />
    }

    if (!checkoutReady) {
      return <CharityPreSelector onContinue={handleCompleteCharitySelect} />
    }

    return <Checkout onBack={() => setCompleteCharitySelect(false)} />
  }

  return (
    <div className={styles.container}>
      <RedBar>
        <div className={styles.redBarWrapper}>
          <div className={styles.stepLabel}>
            {!checkoutReady && <span>Checkout Is Next</span>}
          </div>
          {!loading && (
            <div className={styles.cartDetail}>
              <i className="fa fa-shopping-cart" />${cart.GrandTotal.toFixed(2)}
            </div>
          )}
        </div>
      </RedBar>
      <div className={styles.content}>{renderContent()}</div>
    </div>
  )
}

export default ShoppingCart
