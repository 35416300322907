import classnames from "classnames"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import { Button } from "../../.."
import { DeliveryMethods } from "../../../../Code/Data"
import { emailValidation, isValidUsPhone } from "../../../../Code/Utilities"
import { usePurchaseData } from "../../../../Context/purchase"
import RecipientsUploader from "../../../Common/RecipientsUploader"
import UploadRecipientsInstructionModal from "../../../Common/UploadRecipientsInstructionModal"
import styles from "./styles.module.scss"

function Step4({ onContinue }) {
  const [uploadIndex, setUploadIndex] = useState(0)
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [errorLines, setErrorLines] = useState(null)
  const { digitalCardData, setDigitalCardData } = usePurchaseData()
  const { card,
    deliveryMethod,
    from,
    recipients,
    greeting,
    message,
    customText } = digitalCardData

  const strDeliveryMethod = Object.keys(DeliveryMethods)[deliveryMethod - 1]

  const handleChange = e => {
    setDigitalCardData({
      ...digitalCardData,
      [e.target.name]: e.target.value,
    })
  }

  const handleChangeCustomSection = e => {
    setDigitalCardData({
      ...digitalCardData,
      customText: e.target.value,
    })
  }

  const handleChangeRecipent = index => e => {
    recipients[index][e.target.name] = e.target.value
    setDigitalCardData({
      ...digitalCardData,
      recipients,
    })
  }

  const handleAddRecipient = () => {
    recipients.push({})
    setDigitalCardData({
      ...digitalCardData,
      recipients,
    })
  }

  const handleRemoveRecipient = index => () => {
    if (recipients.length === 1) {
      setDigitalCardData({
        ...digitalCardData,
        recipients: [{ FullName: "", Email: "", Phone: "", }]
      })
    }
    else {
      setDigitalCardData({
        ...digitalCardData,
        recipients: recipients.filter((_, i) => i !== index),
      })
    }
  }

  const handleUploadRecipients = (newRecipients, errorLines) => {
    setErrorLines(errorLines);

    if (newRecipients?.length) {
      setDigitalCardData({
        ...digitalCardData,
        recipients: !uploadIndex
          ? [...newRecipients]
          : [...recipients, ...newRecipients],
      })
      setUploadIndex(uploadIndex + 1)
    }
  }

  const handleClearRecipients = () => {
    setUploadIndex(0)
    setDigitalCardData({
      ...digitalCardData,
      recipients: [
        {
          FullName: "",
          Email: "",
          Phone: "",
        },
      ],
    })
  }

  const handleContinue = () => {
    if (deliveryMethod !== DeliveryMethods.Print) {
      const invalidRecipients = recipients.filter(({ FullName }) => !FullName)
      if (!!invalidRecipients.length) {
        return toast.error("Missing 'FullName' found in the recipients list.")
      }
    }

    if (deliveryMethod === DeliveryMethods.Email) {
      const missingEmails = recipients.filter(({ Email }) => !Email)
      if (!!missingEmails.length) {
        return toast.error("Missing 'Email' found in the recipients list.")
      }
      const invalidEmails = recipients.filter(
        ({ Email }) => !emailValidation(Email)
      )
      if (!!invalidEmails.length) {
        return toast.error("Invalid 'Email' found in the recipients list.")
      }
    }

    if (deliveryMethod === DeliveryMethods.Text) {
      if (!from) {
        return toast.error("Please enter 'From'")
      }
      const invalidRecipients = recipients.filter(({ Cel }) => !Cel)
      if (!!invalidRecipients.length) {
        return toast.error("Missing 'Phone' found in the recipients list.")
      }
      const invalidCels = recipients.filter(({ Cel }) => !isValidUsPhone(Cel))
      if (!!invalidCels.length) {
        return toast.error("Invalid 'Phone' found in the recipients list.")
      }
    }

    onContinue()
  }
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        Let's prepare the details for the gift card.
      </div>
      <div className={styles.content}>
        <div className={styles.detailContent}>
          {deliveryMethod === DeliveryMethods.Text && (
            <div className={styles.detailItem}>
              From
              <input
                name="from"
                placeholder="From"
                value={from}
                onChange={handleChange}
              />
            </div>
          )}
          {deliveryMethod !== DeliveryMethods.Print && (
            <div className={styles.detailItem}>
              To
              {recipients.length > 1 &&
                <div className={styles.btnClear} onClick={handleClearRecipients}>
                  Clear All
                </div>
              }
              <div className={styles.recipients}>
                {recipients.map((recipient, index) => (
                  <div key={index} className={styles.recipient}>
                    <input
                      name="FullName"
                      placeholder="Name"
                      value={recipient.FullName}
                      onChange={handleChangeRecipent(index)}
                    />
                    {deliveryMethod === DeliveryMethods.Email && (
                      <input
                        name="Email"
                        placeholder="Email"
                        value={recipient.Email}
                        onChange={handleChangeRecipent(index)}
                      />
                    )}
                    {deliveryMethod === DeliveryMethods.Text && (
                      <input
                        name="Cel"
                        placeholder="Phone"
                        value={recipient.Cel}
                        onChange={handleChangeRecipent(index)}
                      />
                    )}
                    <i
                      className="fa fa-circle-xmark"
                      onClick={handleRemoveRecipient(index)}
                    />
                  </div>
                ))}
                <div className={styles.addRecipientButton} onClick={handleAddRecipient}>                 
                  <i
                    className="fa fa-plus-circle"
                  />{' '}
                   Add Another Recipient
                </div>
              </div>
            </div>
          )}
          {card?.HasCustomSection && (
            <div className={styles.detailItem}>
              Customize your Card
              <input
                name="customText"
                placeholder="Customize your Card"
                value={customText}
                onChange={handleChangeCustomSection}
              />
            </div>
          )}
          <div className={styles.detailItem}>
            Enter your greeting
            <input
              name="greeting"
              value={greeting}
              placeholder="Your gift card greeting"
              onFocus={event => event.target.select()}
              onMouseUp={() => false}
              onChange={handleChange}
            />
          </div>
          <div className={styles.detailItem}>
            Enter a message
            <textarea
              className={styles.textareaMessage}
              name="message"
              rows={5}
              onFocus={event => event.target.select()}
              onMouseUp={() => false}
              placeholder="Your gift card message"
              value={message}
              onChange={handleChange}
            />
          </div>
          {(deliveryMethod === DeliveryMethods.Print && (message.length || 0) + (greeting.length || 0) > 100) && (
            <div className={styles.printSizeWarning}>
              <strong>Please Note:</strong> As you have chosen to print your cards,
              please be aware that a long card greeting and/or card message may not fit on a single printed page.
              <br />
              <a href="/images/CharityChoiceSamplePrintedCard.pdf" download target="_blank">Download a sample printed Charity Card.</a>
            </div>)
          }
        </div>
        {deliveryMethod !== DeliveryMethods.Print && (
          <div className={styles.uploadContent}>
            Or, upload a recipient file
            <Link
              target="_blank"
              download
              name="downloadTemplate"
              className={classnames(styles.btnDownloadRecipient, styles.red)}
              to={`/Account/Orders/FilesForDownload/Charity-Choice_${strDeliveryMethod}_Recipient_File.csv`}>
              Download Template
              <i className="fa-solid fa-file-arrow-down"></i>
            </Link>
            <div
              className={styles.btnInfo}
              onClick={() => setTooltipOpen(!tooltipOpen)}>
              i
            </div>
            <UploadRecipientsInstructionModal
              open={tooltipOpen}
              deliveryMethod={deliveryMethod}
              onClose={() => setTooltipOpen(false)}
            />
            <RecipientsUploader
              deliveryMethod={deliveryMethod}
              onUpload={handleUploadRecipients}
            />
            {(!!errorLines && errorLines.length > 0) && (
              <div className={styles.uploadIssues}>
                <h3>The following issues have been found in your uploaded file</h3>
                All other lines have been successfully processed.
                <ul>
                  {errorLines.map(issueLine => <li key={issueLine.line}>
                    Line #: <em>{issueLine.line}</em>.<br />
                    Problem Description: <em>{issueLine.message}</em>.<br />
                    Original Line Data: <em>{issueLine.data}</em>.</li>)}
                </ul>
              </div>)
            }
          </div>
        )}
      </div>
      <Button
        className={styles.btnContinue}
        disabled={
          !greeting || !message || (card?.HasCustomSection && !customText)
        }
        onClick={handleContinue}>
        Continue
      </Button>
    </div>
  )
}

export default Step4
