import React, { useRef } from "react"
import { Switch, Route, Redirect, useLocation } from "react-router-dom"
import TitleAndMetaTags from "../../Components/Common/TitleAndMetaTags"
import Header from "../../Components/Shared/Header"
import Footer from "../../Components/Shared/Footer"
import About from "../About"
import WhatIs from "../WhatIs"
import CorporateSolutions from "../CorporateSolutions"
import NPO from "../NPO"
import DAF from "../DAF"
import LandingPage from "../LandingPage"
import AfghanistanRefugees from "../AfghanistanRefugees"
import DigitalCards from "../Purchase/DigitalCards"
import ContactPage from "../Contact"
import TopCharities from "../TopCharities"
import DynamicPage from "../DynamicPage"
import CreateUserRedemptionPage from "../../Components/CreateUserRedemptionPage"
import PhysicalCards from "../PhysicalCards"
import RedeemCodes from "../RedeemCodes"
import Support from "../Support"
import SupportIsraelFoodCard from "../SupportIsraelFoodCard"
import DirectToCharityDonation from "../../Components/DirectToCharityDonation"
import ShoppingCart from "../ShoppingCart"

/*
import PhysicalCards from "../Purchase/PhysicalCards"
import BrandDigitalCard from "../BrandDigitalCard"
import LoginPage from "../LoginPage"
import CreditCheckout from "../CreditCheckout"
import PayPalCheckout from "../PayPalCheckout"
import CorporateCheckout from "../ShoppingCart/CorporateCheckout"
import DonateYourCard from "../DonateYourCard"
import CustomPhysicalCards from "../Purchase/CustomPhysicalCards"
import HonorCards from "../Purchase/HonorCards"
import RedemptionCodes from "../Purchase/RedemptionCodes"
import OrderComplete from "../Purchase/OrderComplete"
*/
export default function Main() {
  const innerWidth = useRef(window.innerWidth);
  //This is used to pass the anchor from the URL to the elements.
  const anchor = window.location.href.slice(window.location.href.indexOf("#") + 1);
  const location = useLocation();
  return (
    <>
      <TitleAndMetaTags />
      {(innerWidth.current >= 1080 || location.pathname !== '/') &&
        <Header />}
      <Switch>
        <Route exact key="home" path="/" render={props => <LandingPage {...props} anchor={anchor} />} />
        <Route exact path="/NPO" component={NPO} />
        <Route exact path="/About" component={About} />
        <Route exact path="/WhatIs" component={WhatIs} />
        <Route exact path="/CorporateSolutions" component={CorporateSolutions} />
        <Route exact path="/Purchase/DigitalCards" component={DigitalCards} />
        <Route path={["/Purchase/eCards", "/Purchase/PrintCards"]}>
          <Redirect to="/Purchase/DigitalCards" />
        </Route>
        <Route exact path="/Purchase/PhysicalCards" component={PhysicalCards} />
        <Route exact path="/Purchase/CustomPhysicalCards">
          <Redirect to="/Purchase/PhysicalCards" />
        </Route>
        <Route exact path="/DAF" component={DAF} />
        <Route exact path="/AfghanistanRefugees" component={AfghanistanRefugees} />
        <Route exact path="/Contact" component={ContactPage} />
        <Route exact path="/TopCharities" component={TopCharities} />
        <Route exact path="/DynamicPage" component={DynamicPage} />
        <Route exact path="/Account/CreateUserRedemptionPage" component={CreateUserRedemptionPage} />
        <Route exact path="/Redeem/(multi)?" component={RedeemCodes} />
        <Route path="/Support/:charityIdOrName" component={Support} />
        <Route exact path="/SupportIsraelFoodCard" component={SupportIsraelFoodCard} />
        <Route exact path="/Purchase/DirectToCharityDonation" component={DirectToCharityDonation} />
        <Route exact path="/Purchase/ShoppingCart" component={ShoppingCart} />
        
        {/* <Route exact path="/Login" component={LoginPage} />
        <Route exact path="/CorporateSolutions/BrandDigitalCard" component={BrandDigitalCard} />
        <Route exact path="/Purchase/PhysicalCards" component={PhysicalCards} />
        <Route exact path="/Purchase/CustomPhysicalCards">
          <Redirect to="/Purchase/PhysicalCards" />
        </Route>
        <Route exact path="/Purchase/HonorCards" component={HonorCards} />
        <Route exact path="/Purchase/RedemptionCodes" component={RedemptionCodes} />
        <Route path="/Purchase/DonateYourCard" component={DonateYourCard} />
        <Route path="/GCE">
          <Redirect to="/Purchase/DonateYourCard" />
        </Route>
        <Route exact path="/Purchase/ShoppingCart" component={ShoppingCart} />
        <Route exact path="/Purchase/CreditCheckout" component={CreditCheckout} />
        <Route exact path="/Purchase/PayPalCheckout" component={PayPalCheckout} />
        <Route exact path="/Purchase/CorporateCheckout" component={CorporateCheckout} />
        <Route exact path="/Purchase/OrderComplete" component={OrderComplete} />
        <Route path="/Support/:charityIdOrName" component={Support} />
        <Route
          path="/Purchase/PhysicalCards/ShipToDonor"
          component={() =>
            props.isLoading ? (
              <LoadingSpinner />
            ) : (
              <PhysicalCards shippingScheme="donor" {...props} />
            )
          }
        /> */}
      </Switch>
      <Footer anchor={anchor} />
    </>
  )
}