import React from "react"
import styles from "./styles.module.scss"

function Success() {
  return <div>
    
  </div>
}

export default Success
