import React, { useEffect, useState } from "react"
import { Loading } from "../../.."
import { usePurchaseData } from "../../../../Context/purchase"
import { srcDefaultImage } from "../../../../images"
import { useHistory } from "react-router-dom"
import { AddDirectToCharityDonationsApi } from "../../../../Code/Data"
import bgImage from "../../../../images/DirectDonationStep3.png"
import styles from "./styles.module.scss"

function Step3({
  items,
  item,
  setItem,
  handleNewDonation,
  handleAdd,
  setErrMsg,
  shareWithCharities,
  setShareWithCharities,
  handleRemoveItem,
}) {
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const label =
    items?.length > 1 ? "Your selected charities" : "Your selected charity"
  // usePurchaseData()

  const handleAddDonation = () => {
    handleAdd()
    handleNewDonation()
  }
  const handleRemoveCharity = item => {
    handleRemoveItem(item)
  }
  const handleContinue = async () => {
    await handleAdd()
    await handleSubmit()
  }

  const handleSubmit = async () => {
    debugger
    const payload = Array.isArray(items)
      ? items.map(({ index, charity, amount }) => {
          return {
            ItemIndex: index,
            Charity: charity,
            ShareWithCharities: shareWithCharities,
            Quantity: 1,
            Denomination: amount,
          }
        })
      : []
    setLoading(true)
    const { Succeeded, ErrorMessage } = await AddDirectToCharityDonationsApi(
      payload
    )
    if (Succeeded) {
      window.location.href = "/Purchase/ShoppingCart"
      // history.push("/Purchase/ShoppingCart")
    } else {
      setErrMsg(ErrorMessage)
    }
    setLoading(false)
  }

  if (loading) {
    return <Loading />
  }

  return (
    <div className={styles.container}>
      <img
        className={styles.img}
        src={bgImage}
        width={
          window.innerWidth >= 768 && window.innerWidth <= 1120 ? "53vw" : "66%"
        }
        alt="Veterans day flag"
      />
      <div className={styles.content}>
        <div className={styles.title}>Ready? Let's donate!</div>

        <div className={styles.lblCharity}>{label}</div>
        <div className={styles.donationList}>
          {items?.length > 0 &&
            items.map(
              (item, index) =>
                item?.charity && (
                  <div key={index} className={styles.preselectedCharity}>
                    <div className={styles.itemImage}>
                      <img
                        src={item?.charity?.LogoUrl || srcDefaultImage}
                        alt={item?.charity?.CharityName}
                      />

                      <div
                        className={styles.btnRemove}
                        onClick={() => handleRemoveCharity(item)}>
                        <i className="fa-solid fa-xmark" />
                      </div>
                    </div>
                    <div className={styles.itemDetail}>
                      <div className={styles.itemName}>
                        {item?.charity.CharityName}
                      </div>
                      <div className={styles.itemDesc}>
                        {item?.charity?.CategoryName}
                      </div>
                    </div>
                    <div className={styles.itemPrice}>
                      ${parseFloat(item?.amount).toFixed(2)}
                    </div>
                  </div>
                )
            )}
          {item?.charity?.CharityName && (
            <div className={styles.preselectedCharity}>
              <div className={styles.itemImage}>
                <img
                  src={item?.charity.LogoUrl || srcDefaultImage}
                  className={styles.img}
                  alt={item?.charity.CharityName}
                />
                <div className={styles.btnRemove} onClick={() => setItem(null)}>
                  <i className="fa-solid fa-xmark" />
                </div>
              </div>
              <div className={styles.itemDetail}>
                <div className={styles.itemName}>
                  {item?.charity?.CharityName}
                </div>
                <div className={styles.itemDesc}>
                  {item?.charity?.CategoryName}
                </div>
              </div>
              <div className={styles.itemPrice}>
                ${parseFloat(item?.amount).toFixed(2)}
              </div>
            </div>
          )}
        </div>
        <button className={styles.addDonationBtn} onClick={handleAddDonation}>
          Add Another Donation
        </button>

        <button
          disabled={!item && items.length < 1}
          className={styles.continueBtn}
          onClick={handleContinue}>
          Continue
        </button>

        <label className={styles.checkbox}>
          <input
            type="checkbox"
            name="ShareWithCharities"
            checked={shareWithCharities}
            onChange={() => setShareWithCharities(!shareWithCharities)}
          />
          I wish to share my information with the charities I selected.
        </label>
      </div>
    </div>
  )
}

export default Step3
