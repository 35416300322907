import React, { useEffect, useState } from "react"
import { Alert, Label, Input } from "reactstrap"
import { useLocation } from "react-router-dom"
import { srcDefaultImage } from "../../../../images"
import { RedeemCodes } from "../../../../Code/Data"
import { parseQuerystring } from "../../../../Code/Utilities"
import { useRedemptionData } from "../../../../Context/redemption"
import UserRedemptionPageHeader from "../../UserRedemptionPageHeader"
import CharitySelector from "../../../Common/CharitySelector"
import CharityDetailModal from "../../../Common/CharityDetailModal"
import { useAppData } from "../../../../Context/appData"
import { Button, RedBar } from "../../../"
import styles from "./styles.module.scss"

function Step2({ onBack, onSuccess, codeAsEntered, charityId }) {
  const [chosenCharities, setChosenCharities] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const {
    redemptionData,
    setRedemptionData,
    redeemCodeInfo,
    redemptionPageInfo,
  } = useRedemptionData()
  const { Charities } = redemptionData
  const { fullCharityList, loading: loadingAppData } = useAppData()
  const [showFullList, setShowFullList] = useState(false)
  const [viewCharity, setViewCharity] = useState()
  const { search } = useLocation()

  const displayData = !!redemptionPageInfo
    ? redemptionPageInfo
    : !!redeemCodeInfo && !!redeemCodeInfo.UserRedemptionPageInfo
      ? redeemCodeInfo.UserRedemptionPageInfo
      : {}
  const {
    Caption,
    HeaderImageURL,
    WelcomeMessage,
    MainColor,
    AccentColor,
    Charities: chosenCharityIds,
  } = displayData
  //If one of the CharityIds is 0, then the button to Show Entire List is not shown
  const hideShowEntireList = chosenCharityIds?.includes(0)

  useEffect(() => {
    const doAutomaticRedeem = async () => {
      const { CharityId } = redeemCodeInfo
      const { CHARITYID, PRESELCHARID, CID } = parseQuerystring(
        window.location.search.toUpperCase()
      )
      const charityToRedeemTo = fullCharityList.find(c =>
        [
          CharityId,
          parseInt(CHARITYID),
          parseInt(PRESELCHARID),
          parseInt(CID),
        ].includes(c.CharityId)
      )

      if (charityToRedeemTo) {
        setRedemptionData({
          ...redemptionData,
          Charities: [charityToRedeemTo],
        })

        if (CharityId || CHARITYID) {
          await handleRedeemCodes(charityToRedeemTo)
        }
      }
    }

    doAutomaticRedeem()
  }, [redeemCodeInfo, search, loading])

  useEffect(() => {
    if (chosenCharityIds?.length && fullCharityList.length) {
      const charities = []
      chosenCharityIds.forEach((cid, index) => {
        if (cid) {
          charities.push(
            fullCharityList.find(({ CharityId }) => cid === CharityId)
          )
        }
      })
      setChosenCharities(charities)
    }
    setShowFullList(!chosenCharityIds?.length)
  }, [chosenCharityIds, fullCharityList])

  const handlePreselectedCharity = charity => {
    setViewCharity()
    setRedemptionData({
      ...redemptionData,
      Charities:
        (Charities.length < 3 || !showFullList) &&
          !Charities.includes(charity.CharityId)
          ? [...Charities, charity]
          : Charities,
    })
  }

  const handleRemoveCharity = charityId => () => {
    setRedemptionData({
      ...redemptionData,
      Charities: Charities.filter(({ CharityId }) => CharityId !== charityId),
    })
  }

  const handleShareInfo = () => {
    setRedemptionData({
      ...redemptionData,
      shareInfo: true,
    })
  }

  const handleRedeemCodes = async charity => {
    const payload = {
      RedeemCode: redemptionData.codeAsEntered,
      Charities: charity
        ? [{ CharityId: charity.CharityId }]
        : Charities.map(({ CharityId }) => ({ CharityId })),
      ShareRedeemerInfo: redemptionData.shareInfo,
    }

    setLoading(true)
    const { Succeeded, ErrorMessage } = await RedeemCodes(payload)
    setLoading(false)

    Succeeded
      ? onSuccess({
        redeemCodeInfo: redeemCodeInfo,
        displayData: displayData,
        charities: redemptionData.Charities,
      })
      : setError(ErrorMessage)
  }

  const renderCharityList = () => {
    if (showFullList) {
      return (
        <CharitySelector
          color={MainColor}
          charities={Charities || []}
          onUpdate={handlePreselectedCharity}
          fromRedemption={true}
        />
      )
    }
    return (
      <>
        <div className={styles.preSelContainer}>
          <div
            className={styles.chosenCharitiesTitle}
            style={{ color: MainColor }}>
            Please select from our chosen charities, or you can search through
            the entire Charity database
          </div>
          <div className={styles.chosenCharities}>
            {chosenCharities.map((charity, index) => (
              <>{!!charity && charity.CharityId && charity.CharityName &&
                <div key={index} className={styles.chosenCharity}>
                  {!!charity?.LogoUrl ? (
                    <div className={styles.charityLogo}>
                      <img
                        src={charity.LogoUrl}
                        onError={e => {
                          e.target.onerror = null
                          e.target.src = "images/default.png"
                        }}
                        alt={charity.CharityName}
                      />
                    </div>
                  ) : (
                    <div className={styles.charityLogo}>
                      {charity.CharityName}
                    </div>
                  )}
                  <div className={styles.charityDetail}>
                    <div
                      className={styles.charityName}
                      style={{ color: AccentColor }}>
                      {charity.CharityName}
                    </div>
                    <div
                      className={styles.charityDesc}
                      onClick={event => {
                        event.stopPropagation()
                        setViewCharity(charity)
                      }}>
                      <i className="fa-solid fa-eye" />
                      View
                    </div>
                  </div>
                  <Button
                    className={styles.btnSelect}
                    outline
                    disabled={Charities.find(
                      ({ CharityId }) => CharityId === charity.CharityId
                    )}
                    style={{ color: AccentColor, borderColor: AccentColor }}
                    onClick={() => handlePreselectedCharity(charity)}>
                    Select
                  </Button>
                </div>}
              </>
            ))}
          </div>
        </div>
        {!hideShowEntireList ? (
          <Button
            className={styles.btnFullList}
            outline
            style={{ color: AccentColor, borderColor: AccentColor }}
            onClick={() => setShowFullList(true)}>
            View Entire Charity List
          </Button>
        ) : null}
      </>
    )
  }

  const { AmountToRedeem, HideAmount } = redeemCodeInfo

  return (
    <>
      <div>
        <UserRedemptionPageHeader
          headerImageURL={HeaderImageURL}
          welcomeMessage={WelcomeMessage}
          caption={Caption}
          mainColor={MainColor}
        />
        <RedBar style={{ backgroundColor: MainColor, height: 30 }}>
          <div className={styles.stepLabel}>
            Step 2 of 2
            <div className={styles.totalPrice}>
              <div className={styles.tag}>Redeem your Gift!</div>
            </div>
          </div>
        </RedBar>
        <div className={styles.container}>
          <div className={styles.content}>{renderCharityList()}</div>
          <div className={styles.charityContent}>
            <Alert color="danger" isOpen={!!error} toggle={() => setError("")}>
              <div className={styles.errorWrapper}>{error}</div>
            </Alert>
            <div className={styles.lblCharity}>
              {Charities.length === 0
                ? "Please choose a charity"
                : `Your selected ${Charities.length === 1 ? "charity" : "charities"
                }`}
            </div>
            <div className={styles.selectedCharityList}>
              {Charities.map(
                ({ CharityId, LogoUrl, CharityName, CategoryName }, index) => (
                  <div key={index} className={styles.preselectedCharity}>
                    <div className={styles.itemImage}>
                      <img src={LogoUrl || srcDefaultImage} alt={CharityName} />
                      <div
                        className={styles.btnRemove}
                        onClick={handleRemoveCharity(CharityId)}>
                        <i
                          className="fa-solid fa-xmark"
                          style={{ color: MainColor }}
                        />
                      </div>
                    </div>
                    <div className={styles.itemDetail}>
                      <div
                        className={styles.itemName}
                        style={{ color: MainColor }}>
                        {CharityName}
                      </div>
                      <div className={styles.itemDesc}>{CategoryName}</div>
                    </div>
                  </div>
                )
              )}
            </div>
            <div className={styles.priceTotal}>
              {!!HideAmount ? <></> : <>Total: ${AmountToRedeem}</>}
              <p>* Amount will be split between the charities evenly.</p>
            </div>
            <Label check className={styles.shareContact}>
              <Input type="checkbox" onChange={handleShareInfo} />{" "}
              <span className={styles.lblTerms}>
                Please share my contact info with the charities I've selected!
              </span>
            </Label>
            <Button
              className={styles.btnContinue}
              style={{ backgroundColor: AccentColor, borderColor: AccentColor }}
              disabled={!Charities.length}
              loading={loading || loadingAppData}
              onClick={() => handleRedeemCodes()}>
              Confirm Selections
            </Button>
            <button className={styles.btnStepBack} onClick={onBack}>
              Go Back
              <i className="fa fa-undo" />
            </button>
          </div>
        </div>
        <CharityDetailModal
          charity={viewCharity}
          onClose={() => setViewCharity()}
          onSelect={handlePreselectedCharity}
        />
      </div>
    </>
  )
}

export default Step2
