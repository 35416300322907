import React, { useEffect, useState } from "react"
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import classnames from "classnames"
import styles from "./styles.module.scss"
import { StatesList } from "../../../../Code/Data"
import LoadingSpinner from "../../LoadingSpinner"

function LocationFilter({ location, setLocation, color }) {
  const CHARITY_LOCALES = [
    {
      value: "INTERNATIONAL",
      label: "International",
    },
    {
      value: "NATIONAL",
      label: "National",
    },
    ...StatesList,
  ]

  const [locationFilterName, setLocationFilterName] = useState("")
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!!StatesList) {
      setIsLoading(false)
      setLocationFilterName(
        location
          ? CHARITY_LOCALES?.find(({ value }) => value === location)?.label
          : "Filter By Location"
      )
    } else setIsLoading(true)
  }, [location, CHARITY_LOCALES])

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <div className={styles.wrapper}>
      <UncontrolledDropdown className={styles.filterWrapper}>
        <DropdownToggle
          className={styles.filterTrigger}
          nav
          caret
          style={{ color }}>
          {locationFilterName}
        </DropdownToggle>
        <DropdownMenu className={styles.filterList}>
          <DropdownItem
            className={classnames(styles.filterItem, styles.clear)}
            onClick={() => setLocation()}>
            Clear
          </DropdownItem>
          {CHARITY_LOCALES.map(({ value, label }) => (
            <DropdownItem
              key={value}
              className={styles.filterItem}
              onClick={() => setLocation(value)}>
              {label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  )
}

export default LocationFilter
