import React, { useEffect, useState } from "react"
import { Label, Input } from "reactstrap"
import { useLocation, useHistory } from "react-router-dom"
import CurrencyInput from "react-currency-input-field"

import { useRedemptionData } from "../../../../Context/redemption"
import { usePurchaseData } from "../../../../Context/purchase"
import { RedeemCodes, AddToDonationCart } from "../../../../Code/Data"
import { useAppData } from "../../../../Context/appData"

import { redeemCodeValidation, runOnceAfterwards } from "../../../../Code/Utilities"
import Modal from "../../../Shared/Modal"
import LoadingSpinner from "../../../Common/LoadingSpinner"
import { Button } from "../../../"
import AddToDonationCheckout from "../../../../Contents/ShoppingCart/AddToDonationCheckout"
import { srcPrepaidCard } from "../../../../images"
import styles from "./styles.module.scss"

function Step1({ onContinue, setSuccess, codeAsEntered: codeAsWasEntered, charityId, multi, rc, codes, setCodes, loggedInUser }) {
  const [errorMessage, setErrorMessage] = useState()
  const [codeAsEntered, setCodeAsEntered] = useState(codeAsWasEntered || "")
  const [code, setCode] = useState(codeAsWasEntered || "") 
  const { fullCharityList } = useAppData()
  const { addToDonationData, setAddToDonationData } = usePurchaseData()
  const { amount } = addToDonationData
  const [codeValid, setCodeValid] = useState(!!rc)
  const [agreeTerms, setAgreeTerms] = useState(false)
  const [urlCharityId, setUrlCharityId] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false)
  const [isActionModalOpen, setActionModalOpen] = useState(false)
  const [isMultiFieldDirty, setMultiFieldsDirty] = useState(!!codeAsWasEntered)
  const [directRedemption, setDirectRedemption] = useState(false)
  const [shoppingCart, setShoppingCart] = useState(false)
  const [paymentSuccess, setPaymentSuccess] = useState(false)
  let selectedCharity
  const {
    redemptionData, getRedeemCodeInfo, setRedemptionData, setRedeemCodeInfo, redeemCodeInfo, redirectOldCustomRedemptionPage
  } = useRedemptionData()
  const { search } = useLocation()
  const history = useHistory()
  const { HasRedeemed, BrandEcard, LogoURL, HideAmount } = redeemCodeInfo || {}

  useEffect(() => {
    if (redeemCodeValidation(codeAsEntered)) {
      setCodeValid(true);
      setErrorMessage('');
    }
    if (!multi) setErrorMessage(redeemCodeInfo?.ErrorMessage)
    if (charityId) {
      setUrlCharityId(charityId)
    }
  }, [])

  // Handle invalid format
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (errorMessage && !redeemCodeValidation(code)) {
        setCodeValid(false)
        if (isMultiFieldDirty) {
          setErrorMessage("The Redemption Code entered is not a valid CharityChoice Redemption Code.")
        }
      }
    }, 600)
    return () => clearTimeout(delayDebounceFn)
  }, [errorMessage])

  // Handle redeemed code
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (HasRedeemed) {
        setCodeValid(false)
        setErrorMessage("The code has already been redeemed.")
      }
    }, 100)
    return () => clearTimeout(delayDebounceFn)
  }, [HasRedeemed])

  useEffect(() => {
    const doNow = async () => {
      if (!redeemCodeInfo) {
        const rci = await getRedeemCodeInfo(codeAsEntered)
        if (rci?.HasCustomRedemptionPage) {
          redirectOldCustomRedemptionPage(rci);
        }
        if (charityId && rci && !rci.CharityId) {
          rci.CharityId = charityId
          setUrlCharityId(charityId)
        }
        const message = !code
          ? "Please enter a Code"
          : (rci.Succeeded
            ? ""
            : (!!rci.ErrorMessage
              ? rci.ErrorMessage
              : "Please check the Code"));
        setErrorMessage(message)
        setRedeemCodeInfo(rci)
      }
    }
    doNow()
  }, [redeemCodeInfo, paymentSuccess])

  useEffect(() => {
    if (multi) {
      setCodes(rc?.split(","));
    }
    else {
      setCodeAsEntered(rc);
      setCode(rc)
    }
  }, [search])

  useEffect(() => {
    async function handleDirectRedemption() {
      if (directRedemption) {
        await RedeemDirectlyToCharity()
      }
    }

    handleDirectRedemption()
  }, [directRedemption])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (redeemCodeValidation(code) && codes.length > 0) {
        codes.push(code)
        setCode("")
      }
      if (!code) {
        setErrorMessage("Please enter a Code.")
      }
    }, 2000)
    return () => clearTimeout(timer)
  }, [code])

  const RedeemDirectlyToCharity = async () => {
    setDirectCharityId()
    const payload = {
      RedeemCode: redeemCodeInfo,
      Charities: selectedCharity ? [{
        CharityId: selectedCharity.CharityId,
        CharityName: selectedCharity.CharityName
      }] : [],
      ShareRedeemerInfo: redeemCodeInfo?.ShowDonor,
    }
    payload.Charities[0].Amount = +redeemCodeInfo.AmountToRedeem
    const { Succeeded, ErrorMessage } = await RedeemCodes(payload)
    if (selectedCharity) {
      setRedemptionData({
        ...redemptionData, Charities: [selectedCharity],
      })
    }
    if (Succeeded !== undefined && Succeeded) {
      setSuccess(true)
    }
    else {
      setErrorMessage(ErrorMessage)
    }
  }

  const handleChangeCode = async (newCode) => {
    if (redeemCodeValidation(newCode)) {
      const rci = await getRedeemCodeInfo(code.toUpperCase())
      if (!rci.Succeeded && rci.ErrorMessage) {
        setErrorMessage(!code
          ? "Please enter a Code."
          : (rci.Succeeded
            ? ""
            : (!!rci.ErrorMessage
              ? rci.ErrorMessage
              : "Please check the Code")))
      }
      else {
        if (rci?.HasCustomRedemptionPage) {
          redirectOldCustomRedemptionPage(rci);
        }
        setCodeValid(true)
        setErrorMessage('');
        if (rci.HasCharityId) {
          setDirectRedemption(true)
        }
        setErrorMessage(null)
        if (rci.UserRedemptionPageInfo) {
          history.push(`/Redeem/Custom/${rci.UserRedemptionPageInfo.UserId}/?RC=${codeAsEntered}`)
        }
      }
    }
    else {
      setErrorMessage(!code ? "Please enter a Code." : `${code} is not a valid CharityChoice Redeem Code`)
    }
    if (!code) setCodeValid(false)
  }
  const handleChangeAmount = event => {
    if (event === undefined) {
      event = 0
    }
    setAddToDonationData({
      ...addToDonationData, amount: event,
    })
  }
  const setDirectCharityId = () => {
    if (!redeemCodeInfo?.CharityId && (charityId || urlCharityId)) {
      const rci = redeemCodeInfo
      if (rci && !rci.CharityId) {
        rci.CharityId = parseInt(charityId || urlCharityId)
      }
      setRedeemCodeInfo({ ...rci })
    }
    selectedCharity = fullCharityList.find(({ CharityId }) => parseInt(CharityId) === parseInt(redeemCodeInfo.CharityId))
  }
  const handleAddCode = async () => {
    const result = await getRedeemCodeInfo(code, loggedInUser.UserId)
    if (redeemCodeValidation(code) && result.Succeeded) {
      codes.push(code)
      const nonEmptyCodes = codes.filter((value) => value.trim() !== "")

      setCodes([...nonEmptyCodes])
      setCode("")
    }
    else {
      setErrorMessage(!code
        ? "Please enter a Code."
        : `${code} is not a valid CharityChoice Redeem Code`)
    }
  }
  const handleChangeMultiCode = index => e => {
    if (!isMultiFieldDirty) setMultiFieldsDirty(true)
    const inputValue = e.target.value.toUpperCase()
    const updatedCodes = codes.filter(value => value !== "")
    updatedCodes[index] = inputValue
    updatedCodes.filter(code => code !== "")
    setCodes(...[updatedCodes])
    setCode(null)
    if (index === codes.length) {
      clearInputById(`${index}`)
    }
    isValid()
  }
  const isValid = () => {
    if (multi) {
      const uniqueValues = new Set(codes)
      let valid = agreeTerms && codes.length > 1 && codes.length === uniqueValues.size
      let errorMessage
      for (let i = 0; i < codes.length; i++) {
        if (!redeemCodeValidation(codes[i])) {
          valid = false
          errorMessage = `${codes[i]} is not a valid CharityChoice Redeem Code`
          break
        }
      }
      if (!valid) {
        if (errorMessage === undefined && isMultiFieldDirty) {
          errorMessage = (codes.length < 1
            ? "This feature is for redemption of multiple codes only."
            : (codes.length !== uniqueValues.size
              ? "Unable to process redeem a code more than once"
              : "Please agree to our terms and conditions"))
        }

      }
      return valid
    }
    else {
      return agreeTerms && codeValid
    }
  }
  const handleChangeData = e => {
    setRedemptionData({
      ...redemptionData, [e.target.name]: e.target.value,
    })
  }
  const handleContinue = async () => {
    if (amount > 0 && !paymentSuccess) {
      if (!isActionModalOpen) {
        setActionModalOpen(true)
        return
      }
      else {
        setActionModalOpen(false)
        setAddToDonationData({
          ...addToDonationData, amount: 0,
        })
      }
    }
    setRedemptionData({
      ...redemptionData, code: multi ? codes.join(",") : code,
    })
    if (redeemCodeInfo?.HasCharityId || urlCharityId || charityId) {
      setDirectCharityId()
      const payload = {
        RedeemCode: redeemCodeInfo.RedeemCode,
        Charities: selectedCharity ? [{
          CharityId: selectedCharity.CharityId,
          CharityName: selectedCharity.CharityName
        }] : [],
        ShareRedeemerInfo: redeemCodeInfo?.ShowDonor,
      }
      if (payload.Charities.length > 0) {
        payload.Charities[0].Amount = +redeemCodeInfo.AmountToRedeem
        const { Succeeded, ErrorMessage } = await RedeemCodes(payload)
        if (Succeeded) {
          setRedemptionData({
            ...redemptionData,
            Charities: payload.Charities
          })
          setSuccess(true)
        }
        else {
          setErrorMessage(ErrorMessage);
        }
      }
      else {
        setErrorMessage("Your redemption could not be completed. Please contact us if this issue recurrs")
      }
      return
    }
    onContinue()
  }
  const clearInputById = (id) => {
    const inputElement = document.getElementById(id)

    if (inputElement) {
      inputElement.value = ""
    }
  }
  const handlePaymentSuccess = () => {
    setPaymentSuccess(true)
  }
  const onPay = async () => {
    const result = await AddToDonationCart(amount)    
    if (result?.Succeeded) {
      setShoppingCart(result.ShoppingCart.Items[0])
      setModalOpen(true)
    }
    setActionModalOpen(false)
  }
  const renderRedeemInput = () => {
    const logoImage = ((BrandEcard && LogoURL) ? <div className={styles.title}>
      <img className={styles.imgLogo} src={LogoURL} alt="Corporate Logo" />
    </div> : null)

    const title = redeemCodeInfo?.AmountToRedeem && HideAmount === false && !!codeValid
      ? `You have $${paymentSuccess ? (parseFloat(redeemCodeInfo.AmountToRedeem) + parseFloat(amount)) : redeemCodeInfo.AmountToRedeem} to redeem!`
      : "Let's redeem your Charity Gift!"
    return (<>
      {logoImage}
      {paymentSuccess &&
        <span className={styles.caption}>You have added ${amount || redeemCodeInfo?.AddedToDonationAmount} to this donation.</span>}
      <div className={styles.title}>{title}</div>
      <img
        className={styles.imgCard}
        src={redeemCodeInfo?.ImageUrl || srcPrepaidCard}
        alt=""
      />
      <div className={styles.redemptionDetail}>
        <div className={styles.inputWrapper}>
          <input
            className={styles.inputCode}
            name="code"
            maxLength={15}
            value={code || ""}
            onChange={e => setCode(e.target.value.toUpperCase().trim())}
            onKeyUp={e => runOnceAfterwards(handleChangeCode(e.target.value.toUpperCase().trim()), 2000)}
            placeholder="Redeem Code"
          />
          <div className={styles.linkChangeCode} onClick={() => handleChangeCode(code)}>{!!code ? "Change Code" : "Enter Code"}</div>
        </div>
        <div className={styles.inputWrapper}>
          <input
            className={styles.inputEmail}
            style={{ fontSize: redemptionData?.email ? "130%" : "32px" }}
            name="email"
            maxLength={40}
            value={redemptionData?.email || ""}
            placeholder="Email Address"
            onChange={handleChangeData}
          />
          <div className={styles.lblEmail}>
            Optional: To receive a confirmation
          </div>
        </div>
      </div>
    </>)
  }

  const renderMultiRedeemInput = () => {
    return (
      <>
        <div className={styles.title}>Enter Your Redemption Codes</div>
        {codes.filter(code => code !== "")
          .map((code, index) => (
            <div key={index} className={styles.multiInputWrapper}>
              <input
                className={styles.multiInputCode}
                name={`code-${index}`}
                placeholder="Input Code"
                value={code}
                onChange={handleChangeMultiCode(index)}
              />
            </div>
          ))}
        <div className={styles.multiInputWrapper}>
          <input
            className={styles.multiInputCode}
            name="code"
            placeholder="Input Code"
            value={code}
            onChange={(e) => setCode(e.target.value.toUpperCase())}
          />
          <i className="fa fa-plus-circle" onClick={handleAddCode} />

        </div>
      </>
    )
  }
  return (
    <div className={styles.container}>
      <Modal
        isOpen={isActionModalOpen}
        onClose={() => setActionModalOpen(false)}
        contentLabel="Action dialog"
      >
        <p className="fs-4">Did you change your mind about adding ${amount} to this donation?</p>
        <button className={styles.yesBtn} onClick={() => handleContinue()}>Yes, Continue</button>
        <button className={styles.noBtn} onClick={() => onPay()}>No, Add ${amount} Now</button>
      </Modal>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        contentLabel="Action dialog"
      >
        <AddToDonationCheckout item={shoppingCart} amount={amount} onPaymentSuccess={handlePaymentSuccess}
          code={code} />
      </Modal>
      <div className={styles.content}>
        {multi ? renderMultiRedeemInput() : renderRedeemInput()}
        {(HasRedeemed || !codeValid || !redeemCodeInfo || !redeemCodeInfo.AmountToRedeem)
          ? ((!codeValid || !!errorMessage || HasRedeemed)
            ? <div className={styles.errorMessage}>{errorMessage}</div>
            : <LoadingSpinner />)
          : <>
            <Label check className={styles.termsWrapper}>
              <Input
                className={styles.termsRadio}
                type="radio"
                checked={agreeTerms === true}
                onChange={() => setAgreeTerms(!agreeTerms)}
              />{" "}
              <span className={styles.lblTerms}>
                I affirm that I am the gift recipient or that I received this code
                in a rewards program by me redeeming my points. View our &nbsp;
                <a href="/FAQs.aspx#Link24">Terms and Conditions.</a>
              </span>
            </Label>
            <Button
              className={`${styles.btnContinue} m-3`}
              disabled={!isValid()}
              onClick={handleContinue}>
              Continue
            </Button>
            {codeValid && !multi && redeemCodeInfo?.HasCharityId !== true && !paymentSuccess &&
              <div className={styles.divAddToDonation}>
                <div>
                  To add to this donation, enter an amount
                </div>
                <div
                  title={"When you designate your charity gift, you may add your own funds to increase the benefit for your favorite charities. The total sum of your charity gift and whatever you have added, will be divided equally amongst the chosen charities. You will receive an emailed tax receipt for the additional funds."}>
                  <CurrencyInput className={styles.amountInput} onValueChange={handleChangeAmount}
                    allowNegativeValue={false} prefix={"$"} value={amount.toString()} />
                </div>
                <div>
                  {amount > 0 &&
                    <div className={styles.addToDonationBtn} onClick={() => onPay()}> Add
                      ${amount} Now </div>
                  }
                </div>
              </div>}
          </>
        }
      </div>
    </div>)
}

export default Step1

