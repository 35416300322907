import React, { useEffect, useState } from "react"
import { useUserData } from "../../Context/user"
import Success from "./Success"
import Steps from "./Steps"

function DirectToCharityDonation() {
  const [success, setSuccess] = useState(false)
  const { loggedInUser } = useUserData()
  const [isEditMode, setEditMode] = useState(false)

    return (success
      ? <Success setEditMode={setEditMode} />
      : <Steps onSuccess={() => setSuccess(true)} setEditMode={setEditMode} isEditMode={isEditMode}/>
    )
}

export default DirectToCharityDonation
