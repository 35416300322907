import { toast } from "react-toastify"
import { get, post } from "./IApi"
import { DeliveryMethods, ShoppingCartItemTypes } from "./Data"
import $ from "jquery"

Object.compare = function(obj1, obj2) {
  if (obj1 !== Object(obj1) || obj2 !== Object(obj2)) {
    return obj1 === obj2
  }
  for (let p in obj1) {
    if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false

    switch (typeof obj1[p]) {
      case "object":
        if (!Object.compare(obj1[p], obj2[p])) return false
        break
      case "function":
        if (
          typeof obj2[p] === "undefined" ||
          (p !== "compare" && obj1[p].toString() !== obj2[p].toString())
        )
          return false
        break
      default:
        if (obj1[p] !== obj2[p]) return false
    }
  }

  for (let p in obj2) {
    if (typeof obj1[p] === "undefined") return false
  }
  return true
}

export function convertDataToFormData(data) {
  const formData = new FormData()
  for (let propertyName in data) {
    if (!data.hasOwnProperty(propertyName) || !data[propertyName]) continue
    if (data[propertyName] instanceof Date)
      formData.append(propertyName, data[propertyName].toISOString())
    else if (data[propertyName] instanceof Array) {
      data[propertyName].forEach((element, index) => {
        const tempFormKey = `${propertyName}[${index}]`
        convertDataToFormData(element, formData, tempFormKey)
      })
    }
    else if (
      typeof data[propertyName] === "object" &&
      !(data[propertyName] instanceof File)
    )
      convertDataToFormData(data[propertyName], formData, propertyName)
    else formData.append(propertyName, data[propertyName].toString())
  }
  return formData
}

export function clone(o) {
  return JSON.parse(JSON.stringify(o))
}

/**
 * Tests whether the given text is in a valid CharityChoice Redeem Code format
 * @param {string} code
 * @returns
 */
export function isValidRedeemCode(code) {
  if (!code || code.length < 6) return false
  return /^[A-Z]+\d+(C\d+)?$/i.test(code)
}

/**
 * Parses the querystring of the given URL to a name/value dictionary object
 * @param {string} url URL to extract the querysting from.
 * @param {boolean?} upperCaseNames Should param names be transformed to upper case?
 */
export function parseQuerystring(url, upperCaseNames) {
  const dict = {},
    vars = url.replace(/^[^?]*\?/, "").split("&")
  for (var i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=")
    const name = upperCaseNames ? pair[0].toUpperCase() : pair[0]
    dict[name] = pair[1]
  }
  return dict
}

/**
 * Try to parse json to an object. If fails returns original unparsed value
 * @param {string} json
 */
export function TryParseJson(json) {
  try {
    return JSON.parse(json)
  } catch (e) {
    return json
  }
}

/**
 * Validates an email address
 * @param {*} address
 * @param {*} quiet
 */
export async function isValidEmailAddress(address) {
  if (address.match(/^.+@[^.].*\.[a-z]{2,}$/i)) {
    return true
  }
  const response = await get(
    `generalUtils/isValidEmail/${encodeURIComponent(address)}`,
  )
  if (response && response.Succeeded) {
    return response.IsValid
  }
}

function isNullOrWhitespace(input) {
  return !input || !input.trim()
}

export function isValidUsPhone(cel) {
  if (isNullOrWhitespace(cel) || cel.Length < 10) return false
  const numsOnly = cel.trim().replace(/[^0-9]/gi, "")
  if (numsOnly.Length < 10) return false
  return /^1?[2-9][0-9]{2}[2-9][0-9]{6}$/.test(numsOnly)
}

export function emailValidation(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}
/**
 * Tests whether the given text is in a valid CharityChoice Redeem Code format
 * @param {string} code
 * @returns
 */
export function redeemCodeValidation(code = "") {
  if(!code){
    return false
  }
  var regEx = /^[A-Z]+\d+(C\d+)?$/
  return code.match(regEx)
}

/**
 * Scroll to the given element selector (or jquery selector)
 * @param {string} element
 * @param {boolean} animate scroll smoothly. To  scroll instantaneously, this argument needs to === false
 * @param {*} deductPixels Can be either a number of pixels from the top of the element or "start", "center", "end", or "nearest".
 */
export function scrollToElement(element, animate, deductPixels) {
  //If we are in an iframe, we run this on the parent
  if (
    window.parent &&
    window.parent !== window &&
    window.parent.document.scrollToElement
  ) {
    window.parent.document.scrollToElement(element, animate, deductPixels)
    return
  }

  const obj = $(element)
  if (animate) {
    $("html,body").animate(
      { scrollTop: obj.offset().top - (deductPixels || 0) },
      "slow",
    )
  }
  else {
    if (obj.length) {
      obj[0].scrollIntoView(true, {
        behavior: animate !== false ? "smooth" : "instant",
        block: "end",
      })
    }
  }
}

/**
 * Gets the name of the time zone for the given date or today.
 * Can be Eastern Standard Time or Daylight savings Time
 * @param {*} sendDate
 */
export async function getTimeZoneName(sendDate) {
  const response = await post(sendDate, "generalUtils/timeZoneName")
  if (response && response.Succeeded) {
    return response.TimeZoneName
  }
}

export function convertDateToUTC(date) {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  )
}

export function getDeliveryMethodName(deliveryMethod, short) {
  switch (deliveryMethod) {
    case DeliveryMethods.Text:
      return `Text${short ? "" : " Message"}`
    case DeliveryMethods.Social:
      return `Share${short ? "" : " via messaging"}`
    default:
      return "Email"
  }
}

export function needsShippingInfo(shoppingCartItem) {
  return (
    shoppingCartItem &&
    (shoppingCartItem.Type === "Custom Physical Cards" ||
      shoppingCartItem.Type === "Physical Cards")
  )
}

/**
 * Get the field order for the fields in the uploaded csv file from the csv file header.
 * Returns an object containing an index for the firstName, lastName, email, cel.
 * @param header
 * @param deliveryMethod
 * @returns {{firstName: number, lastName: number, errorMessage: string, cel: number, email: number}}
 */
function getCsvFieldOrder(header, deliveryMethod) {
  const headers = csvToArray(header.trim())
  const order =
    {
      firstName: -1,
      lastName: -1,
      email: -1,
      cel: -1,
      errorMessage: "",
    }

  for (let i = 0; i < headers.length; i++) {
    if (/f.*name/i.test(headers[i])) {
      order.firstName = i
    }
    else if (/l.*name/i.test(headers[i])) {
      order.lastName = i
    }
    else if (/mail/gi.test(headers[i])) {
      order.email = i
    }
    else if (/((phone)|(cel))/gi.test(headers[i])) {
      order.cel = i
    }
  }
  if (order.firstName === -1) {
    order.errorMessage = "The \"First Name\" field was not be found."
  }
  if (order.lastName === -1) {
    order.errorMessage += " The \"Last Name\" field was not be found."
  }
  if (deliveryMethod === DeliveryMethods.Email && order.email === -1) {
    order.errorMessage += " The \"Email\" field was not be found."
  }
  if (deliveryMethod === DeliveryMethods.Text && order.cel === -1) {
    order.errorMessage += " The \"Phone\" or \"Cel\" field was not be found."
  }

  return order
}

function validateRetailerCardCSV(header) {
  const headers = csvToArray(header.trim())
  const [RetailerId, CardNumber, PIN, Balance] = headers
  return (
    RetailerId === "RetailerId" &&
    CardNumber === "CardNumber" &&
    PIN === "PIN" &&
    Balance === "Balance"
  )
}

/**
 * Retrieves a list of recipients from the given csv file.
 * @param {File} file The file to parse.
 * @param {[{ firstName: string, lastName: string, email: string, phone: string }]} currentRecipients The current list of recipients.
 * @param {DeliveryMethods} deliveryMethod The deliveryMethod currently selected
 * @returns {{recipients:[{ firstName: string, lastName: string, email: string, phone: string }],errorLines: { line:number, message:string, data:string })}} The list of recipients retrieved from the given file.
 */
export function readRecipCsvFile(file, deliveryMethod) {
  const reader = new FileReader()
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort()
      reject(
        new DOMException(
          "There was a problem parsing the file. Please check the file and upload again.",
        ),
      )
    }
    reader.onload = event => {
      const csv = event.target.result,
        recipients = [],
        errorLines = []
      let lastRecip = false,
        addedOne = false,
        currentLine = 0
      if (csv) {
        const lines = csv.split("\n")
        const fieldOrder = getCsvFieldOrder(lines[0], deliveryMethod)
        if (fieldOrder.errorMessage) {
          toast.error("Invalid file. " + fieldOrder.errorMessage)
          reject(
            new DOMException(
              `Invalid file. ${fieldOrder.errorMessage} Please check the file and upload again.`,
            ),
          )
        }
        for (let i = 1; i < lines.length; i++) {
          currentLine = i + 1
          const line = lines[i].trim().replace("'", "’"),
            parsed = csvToArray(line)
          if (parsed && parsed.length) {
            try {
              const firstName = (parsed[fieldOrder.firstName] || "").trim(),
                lastName = (parsed[fieldOrder.lastName] || "").trim(),
                email = (parsed[fieldOrder.email] || "").trim(),
                phone = (parsed[fieldOrder.cel] || "").trim()
              if (deliveryMethod === DeliveryMethods.Email) {
                if (!email) {
                  errorLines.push({ line: currentLine, message: "Missing email address", data: line })
                  continue
                }
                else if (!email.match(/^[^@\s]+@[^@\s]+\.[^@\s]+$/)) {
                  errorLines.push({ line: currentLine, message: "Supplied email is not valid", data: line })
                  continue
                }
              }
              else if (deliveryMethod === DeliveryMethods.Text) {
                if (!phone) {
                  errorLines.push({ line: currentLine, message: "Missing phone number", data: line })
                  continue
                }
                else if (parseInt(phone.replace(/[^0-9]/gi, "")).toString().length !== 10) {
                  errorLines.push({
                    line: currentLine,
                    message: "Supplied phone number is a not valid US phone number",
                    data: line,
                  })
                  continue
                }
              }
              if (lastRecip) {
                lastRecip.firstName = firstName
                lastRecip.lastName = lastName
                lastRecip.email = email
                lastRecip.phone = phone
                //set lastRecip to empty recipient
                lastRecip = recipients.find(r => !r.firstName)
              }
              else {
                recipients.push({
                  FullName: `${firstName} ${lastName}`,
                  Email: email,
                  Phone: phone,
                })
                addedOne = true
              }
            } catch (e) {
              errorLines.push({ line: currentLine, message: e.message, data: line })
            }
          }
          else {
            if (line) {
              errorLines.push({ line: currentLine, message: "Invalid CSV data.", data: line })
            }
          }
        }
      }
      addedOne
        ? resolve({ recipients, errorLines })
        : reject(
          new DOMException(
            "No recipients were found in the file. Please check the file and upload again.",
          ),
        )
    }
    reader.readAsText(file)
  })
}

/**
 * Retrieves a list of Retailer Gift Cards from the given csv file.
 * @param {File} file The file to parse.
 * @returns {[{ RetailerId: number, CardNumber: string, PIN: string, Balance: number }]} The list of recipients retrieved from the given file.
 */
export function readRetailerCardCsvFile(file) {
  const reader = new FileReader()

  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort()
      reject(
        new DOMException(
          "There was a problem parsing the file. Please check the file and upload again.",
        ),
      )
    }
    reader.onload = event => {
      const csv = event.target.result,
        cards = []
      let addedOne = false
      if (csv) {
        const lines = csv.split("\n")
        if (!validateRetailerCardCSV(lines[0])) {
          toast.error("Invalid file format. Please check the file and upload again.")
          reject(
            new DOMException(
              "Invalid file. Please check the file and upload again.",
            ),
          )
        }
        for (let i = 0; i < lines.length; i++) {
          const line = lines[i].trim(),
            parsed = csvToArray(line)
          if (parsed && parsed.length) {
            const [RetailerId, CardNumber, PIN, Balance] = parsed
            if (RetailerId === "RetailerId") continue //first line...
            cards.push({ RetailerId: parseInt(RetailerId), CardNumber, PIN, Balance: parseFloat(Balance) })
            addedOne = true
          }
        }
      }
      addedOne
        ? resolve(cards)
        : reject(
          new DOMException(
            "No Retailer Gift Cards were found in the file. Please check the file and upload again.",
          ),
        )
    }
    reader.readAsText(file)
  })
}

/**
 * Return array of string values, or NULL if CSV string not well formed.
 * @param {*} text Text to parse
 * @returns {[]} Returns array of values for this csv line
 */
function csvToArray(text) {
  const re_valid =
      /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/,
    re_value =
      /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g,
    a = []
  //Return NULL if input string is not well formed CSV string.
  if (!re_valid.test(text)) return null
  text.replace(
    re_value,
    //"Walk" the string using replace with callback.
    (m0, m1, m2, m3) => {
      //Remove backslash from \' in single quoted values.
      if (m1 !== undefined) a.push(m1.replace(/\\'/g, "'"))
      //Remove backslash from \" in double quoted values.
      else if (m2 !== undefined) a.push(m2.replace(/\\"/g, "\""))
      else if (m3 !== undefined) a.push(m3)
      //Return empty string.
      return ""
    },
  )
  //Handle special case of empty last value.
  if (/,\s*$/.test(text)) a.push("")
  return a
}

export function cleanHtmlTags(txt) {
  if (txt)
    return txt.replace(/<(.|\n)*?>/g, "")
}

export function getHtmlInnerText(htmlString) {
  return $(htmlString).text()
}

export function getCustomSectionHtml(customText, customSectionDefaultHtml) {
  let customSectionHtml = null
  if (customSectionDefaultHtml && customText) {
    customSectionHtml = customSectionDefaultHtml.replace(getHtmlInnerText(customSectionDefaultHtml), customText)
  }
  return customSectionHtml
}

export function loadScript(url) {
  return new Promise((resolve, reject) => {
    $.getScript(url)
      .done(script => resolve(script))
      .fail((jqxhr, settings, exception) =>
        reject(new DOMException(`Problem loading ${url}. ${exception.message}`)),
      )
  })
}

export function validateRetailerCard(retailer, cardNumber, pin, balance) {
  return (
    retailer &&
    validateNumberAndPin(retailer, cardNumber, pin) &&
    validateBalance(retailer, balance)
  )
}

export function validateNumberAndPin(retailer, cardNumber, pin) {
  return (
    (cardNumber && !retailer.ValidationRules.length) ||
    retailer.ValidationRules.find(
      val =>
        (!val.CardNumberLength || val.CardNumberLength === cardNumber.length) &&
        (!val.PinLength || (pin && val.PinLength === pin.length)),
    )
  )
}

export function validateBalance(retailer, balance) {
  return (
    balance &&
    (!retailer.Minimum || balance >= retailer.Minimum) &&
    (!retailer.Maximum || balance <= retailer.Maximum)
  )
}

/**
 * Parses a 'coded URL' for redirection after user is logged in.
 * @param {*} codedUrl
 */
export async function parseLoginURL(codedUrl) {
  const response = await get(`generalUtils/parseCodedUrl/${codedUrl}`)
  if (response && response.Succeeded) {
    return response.Url
  }
}

/**
 * Gets a 'coded URL' for redirection after user is logged in.
 * @param {*} url
 */
export async function getCodedLoginUrl(url) {
  const response = await get(
    `generalUtils/getCodedLoginUrl?relativeUrl=${encodeURIComponent(url)}`,
  )
  if (response && response.Succeeded) {
    return response.CodedUrl
  }
}

/**
 * Redirect to the old login page with the current URL as the return page.
 * @param { isLogin, isCorporate }
 *    isLogin: true | false (false for login, true for signup)
 *    isCorporate: true | false
 */
export async function loginOldAndReturn(option) {
  const { isLogin, isCorporate } = option
  const path = `${window.location.pathname}${window.location.search}`
  const returnUrl = await getCodedLoginUrl(path)
  if (returnUrl) {
    let href = `/Login/?ReturnPage=${returnUrl}`
    if (!isLogin) {
      href += isCorporate ? "&CA=1#createAccount" : "#createAccount"
    }
    window.location = href
  }
}

/**
 * Register the current page in AnalyticsPageView
 * @param {{page_title:string, page_location:string, page_path: string}} options
 */
export function registerAnalyticsPageView(options) {
  window.gtag("config", "G-WV0Q3CL3KJ", options)
}

/**
 * Register a purchase in Google analytics
 * @param {{}} data
 */
export function registerAnalyticsPurchase(data) {
  window.gtag("event", "purchase", data)
}

/**
 * Filters a list of Charities for the given search term. Can be restricted to given Category.
 * @param {[{CharityId:number,CharityName:string,CategoryId:number,CategoryName:string,ShortDescription:string,IsLocal:boolean,LogoURL:string,Locations:[string]}]} charityList
 * @param {string} searchTerm filter for charities that have all the words of the search term in their name (or in their ShortDescription - if includeDescription is truthy)
 * @param {number} categoryId optionally restrict to one of the charity categories.
 * @param {"all"|"main"|"local"} listName optionally restrict to one of the lists. Default is 'all'
 * @param {bool} includeDescription should the charity's short description be searched as well?
 * @returns {[{CharityId:number,CharityName:string,CategoryId:number,CategoryName:string,ShortDescription:string,IsLocal:boolean,LogoURL:string,Locations:[string]}]}
 */
export function filterCharities(
  charityList,
  searchTerm,
  categoryId,
  listName,
  searchDescription,
) {
  return categoryId || (searchTerm && searchTerm.length)
    ? charityList.filter(
      charity =>
        (!listName ||
          listName === "all" ||
          (listName === "main" && !charity.IsLocal) ||
          (listName === "local" && charity.IsLocal)) &&
        (!categoryId || charity.CategoryId === categoryId) &&
        (!searchTerm ||
          isSearchMatch(
            charity.CharityName +
            (searchDescription ? " " + charity.ShortDescription : ""),
            searchTerm,
          )),
    )
    : charityList
}

/**
 * Searches 'googlishly' for a search term within some text.
 * A correct match is if all of the given search words match any whole or part of a word in the text.
 * The wildcards * or % can be used in place of any number of characters.
 * Most characters are ignored. Besides for - _ . * and space
 * @param {string} text The text to search in
 * @param {string} searchTerm The term to search for
 * @returns {boolean}
 */
export function isSearchMatch(text, searchTerm) {
  var words = searchTerm.split(" ")
  for (var i = 0; i < words.length; i++) {
    var regEx =
      "\\b\\S*" +
      words[i].replace(/[*%]/gi, ".*").replace(/[^\w\- .*]/gi, ".") +
      "\\S*\\b"
    if (!new RegExp(regEx, "gi").test(text)) {
      return false
    }
  }
  return true
}

/**
 * Download the given text as a file with the given name.
 * @param {string} fileText
 * @param {string} fileName
 */
export function downloadFile(fileText, fileName) {
  const mimeType = "text/csv;encoding:utf-8",
    a = document.createElement("a")
  if (navigator.msSaveBlob) {
    // IE10
    navigator.msSaveBlob(
      new Blob([fileText], {
        type: mimeType,
      }),
      fileName,
    )
  }
  else if (URL && "download" in a) {
    //html5 A[download]
    a.href = URL.createObjectURL(
      new Blob([fileText], {
        type: mimeType,
      }),
    )
    a.setAttribute("download", fileName)
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
  else {
    window.location.href =
      "data:application/octet-stream," + encodeURIComponent(fileText)
  }
}

export function checkCartNoDirectDonations(shoppingCart) {
  const ineligibleItemTypes = [
    ShoppingCartItemTypes.DirectToCharityDonation,
    ShoppingCartItemTypes.HonorCardToEmail,
    ShoppingCartItemTypes.HonorCardToPrint,
  ]
  const hasEligibleItem = shoppingCart.Items.reduce(
    (acc, item) =>
      acc || !ineligibleItemTypes.includes(item.ShoppingCartItemTypeId),
    false,
  )
  return hasEligibleItem
}

/**
 * @returns Gets a GUID string
 */
export function getGuid() {
  // eslint-disable-next-line no-mixed-operators
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16),
  )
}

const usZipRe = new RegExp("^\\d{5}(-{0,1}\\d{4})?$")
const caZipRe = new RegExp(
  /([ABCEGHJKLMNPRSTVXY]\d)([ABCEGHJKLMNPRSTVWXYZ]\d){2}/i,
)

export function isValidZip(zipCode) {
  if (!zipCode || !zipCode.length) {
    return false
  }
  if (usZipRe.test(zipCode.toString())) {
    return true
  }

  if (caZipRe.test(zipCode.toString().replace(/\W+/g, ""))) {
    return true
  }
  return false
}

export function expirationDateIsValid(creditData) {
  let today = new Date()
  let someday = new Date(
    `20${creditData.ExpirationDateYear}-${creditData.ExpirationDateMonth}-01`,
  )
  return someday > today
}

export function validateCharityId(charityId) {

}
export function checkLuhn(n) {
  let c,
    s = 0,
    m = 0,
    l = n.length
  while (l--) {
    c = parseInt(n.charAt(l), 10) << m
    s += c - (c > 9) * 9
    m ^= 1
  }
  return s % 10 === 0 && s > 0
}


export function runOnceAfterwards(callback, milliseconds) {
  let timeout = null
  return () => {
    clearTimeout(timeout)
    timeout = null
    timeout = setTimeout(() => {
      if (!timeout) return
      clearTimeout(timeout)
      timeout = null
      callback()
    }, milliseconds)
  }
}